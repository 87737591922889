@charset "UTF-8";

/*
 モーダルに関するスタイル（SP用）
*/

@media screen and (max-width: 413px) {
  .pfReview__create--modal {
    position: relative;
    top: 50%;
    left: 50%;
    right: 40px;
    bottom: 40px;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 5px;
    outline: none;
    padding: 20px;
    color: rgb(0, 0, 0);
    z-index: 16;
    text-align: center;
    transform: translate(-50%, -50%);
  }

  .pfReview__create--modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    padding: 20px;
    z-index: 22;
  }

  .pfReview__modal-text {
    margin-bottom: 30px;
    margin-top: 40px;
    line-height: 2.5em;
    font-size: 13.5px;
  }

  .pfReview__modal-btn--primary {
    background-color: #ffc864;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 16px;
    height: 44px;
    border: 0;
    border-radius: 100px;
    cursor: pointer;
    -webkit-transition: all 0.1s ease;
    transition: all 0.1s ease;
    font-weight: normal;
    width: 100%;
    box-shadow: none;
    display: block;
    text-align: center;
  }
}
