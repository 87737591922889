:root {
  /*
  NaviAPI から取得するスタイル情報を変数として再定義するために宣言
  フッターを最下部に固定するためのスタイル
  NOTE: NaviAPI から取得するスタイルが変更されるとここも変更する必要があるため対策を考える必要あり
  */
  --header-height: 48px;
  --header-border-bottom-height: 1px;
  --link-top-height: 42px;
  --sub-footer-height: 140px;
  --footer-height: 78px;
  --page--complete-and-write-padding-bottom-height: 40px;
}
