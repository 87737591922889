/* レビュー関連ページのメインコンテンツに関するスタイル（PC 用）
    -> SP はページは縦に伸びない方がいいため本スタイルを反映しない */
div #review-main-pc {
  /* TODO: 最後の 10px がどこで定義されているか分かり次第変数化 */
  min-height: calc(100vh - var(--header-height) - var(--header-border-bottom-height) - var(--link-top-height) - var(--sub-footer-height) - var(--footer-height) - var(--page--complete-and-write-padding-bottom-height) - 10px);
  background: #fbfbfb;
}

/* レビュー関連ページのエラーコンテンツに関するスタイル（PC 用）
    -> SP はページは縦に伸びない方がいいため本スタイルを反映しない */
div #error-main-pc {
  /* TODO: 最後の 10px がどこで定義されているか分かり次第変数化 */
  min-height: calc(100vh - var(--header-height) - var(--header-border-bottom-height) - var(--footer-height) - var(--page--complete-and-write-padding-bottom-height) - 10px);
  background: #fbfbfb;
}

/* ============================= 以下のスタイルについて ================================
NaviAPI から取得するスタイルをレビューページ用に変数を使う形で再定義
TODO: NaviAPI から取得するスタイルが変更されるとここも変更する必要があるため対策を考える必要あり
===================================================================================== */
header._n4v1-header {
  height: var(--header-height);
  border-bottom: var(--header-border-bottom-height) solid #e9ebef;
}

footer._n4v1-footer {
  height: var(--footer-height);
}

#review-post.pfReview__page--complete.pfReview__page--write.pfReview {
  padding-bottom: var(--page--complete-and-write-padding-bottom-height);
}
