/*
 PC および SP のスタイルを読込
 どちらのスタイルを使用するかは各スタイル内の @media にて判別
*/
@import url("./pc/review.css");
@import url("./sp/review.css");
@import url("./pc/modal.css");
@import url("./sp/modal.css");

/* PC と SP で共通のスタイル */

.pfReview__btn a.pfReview__btn--textlink {
  cursor: pointer;
}
