#error h1 {
  padding-bottom: 5px;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  border-bottom: #212121 solid 1px;
}

.bx-back {
  border-top: 1px dotted #bebebe;
  background-color: #f5f5f5;
  text-align: center;
  padding: 10px 0;
  margin-bottom: 0;
}

[class^="btn-rct-"] {
  height: auto;
  padding: 6px 6px;
  box-sizing: border-box;
  font-weight: bold;
  text-align: center;
}

/* サーバエラー画面のロゴ用 */
#server-error ._n4v1-header {
  display: flex;
  align-items: center;
}

/* サーバエラー画面のロゴ用 */
#server-error ._n4v1-logo-pc img {
  max-width: 100%;
  max-height: 24px;
  margin-left: 12px;
}

/* サーバエラー画面のロゴ用 */
#server-error ._n4v1-logo-sp img {
  max-width: 100%;
  max-height: 24px;
  margin-left: 12px;
}

/* サーバエラー画面のSPフッター用 */
#server-error ._n4v1-mainfooter-sp {
  width: 90%;
  margin: 0 auto;
}

/* サーバエラー画面のSPフッター用 */
#server-error ._n4v1-mainfooter-sp span {
  display: inline-block;
  padding: 5px 0 5px 0;
}

/* サーバエラー画面のSPフッター用 */
#server-error ._n4v1-copyright-sp {
  margin: 10px 0;
  text-align: center;
}
