/* ==============================[ reset ] */
.page-maintenance_sp * {
  margin: 0;
  padding: 0;
}

.page-maintenance_sp li { list-style: none; }

/* ==============================[ .page-maintenance_sp ] */
.page-maintenance_sp {
  word-break: normal;
  word-wrap: break-word;
  padding-bottom: 30px;
  background: #f2f2f2 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAACtJREFUeNpiZGBgEGDAAliA+DcuiT/YJBiBmBmrxP///7GJMzAx4AAAAQYAc/YFFQHsMS4AAAAASUVORK5CYII=) repeat-x;
  color: #666;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 1.7;
  margin: 0 auto;
}

.page-maintenance_sp .item { margin: 32px 0; }
.page-maintenance_sp .item p { margin: 14px 0; }

/* ==============================[ .area-head ] */
.page-maintenance_sp .d-headline {
  position: relative;
  margin: 0 auto 30px;
  padding: 40px 2px 20px 3px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAECAYAAABCxiV9AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAC9JREFUeNpiVD50XYKBgeEFEOsB8RkgZmWAgAdMuCSA2JIJlwRIExMuCZAmgAADAFzJDaOql65+AAAAAElFTkSuQmCC) repeat-x left bottom;
  color: #333;
  font-size: 20px;
  text-align: center;
  text-shadow: 0 1px 0 white;
  border: none;
}

.page-maintenance_sp .d-headline::after {
  position: absolute;
  right: 50px;
  bottom: 5px;
  width: 75px;
  height: 32px;
  content: "";
}

/* ==============================[ .area-content ] */
.page-maintenance_sp .term {
  margin-top: 20px;
  background: #fff;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  font-size: 12px;
}

.page-maintenance_sp .item .date {
  font-size: 16px;
  text-align: center;
  color: #c00;
  margin: 10px auto;
}

.page-maintenance_sp .item .date span {
  display: block;
}

.page-maintenance_sp .item .caution {
  font-size: 12px;
  color: #666;
}

.page-maintenance_sp .item .caution::before {
  content: "※";
}

.page-maintenance_sp a {
  color: #75ceda;
  font-size: 14px;
  font-weight: bold;
}

#w .page-maintenance_sp > p {
  margin: 0;
}
